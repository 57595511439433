import {
  NOTIFICATION_ON,
  NOTIFICATION_OFF,
  SET_NEW_MENU_KEY,
  T_STATUS_SHOW,
  T_STATUS_LOADING,
  T_STATUS_CLOSE_LOADING,
  SET_NEW_FONT,
  LOAD_PARTY,
  ADD_PARTY,
  EDIT_PARTY,
  ACCESS,
  TOGGLE_COLLAPSE,
  GET_MY_DETAILS,
  GET_MY_LEAVE_BALANCE,
  GET_HOLIDAY,
  GET_NOTICE,
  GET_MY_ATTENDANCE,
  GET_MY_LEAVE,
  SOCKET_ON,
  GET_MESSAGE,
  GET_PAYHEAD,
  GET_EMPLOYEE,

} from '../constants/action-types'
import { GetData, SecuredGetData } from '../../Service/service'
import { authService } from '../../Helper/AuthService'
import SocketService from '../../Helper/SocketService'



export function showNotification({ type, status, text,action,link }) {
  return function (dispatch) {
    dispatch({ type: NOTIFICATION_ON, payload: { type, status, text,action,link } })
    setTimeout(() => dispatch({ type: NOTIFICATION_OFF, payload: '' }), 2000)
  }
}

export function changeFontSettings({ fontSettings }) {
  return function (dispatch) {
    dispatch({ type: SET_NEW_FONT, payload: fontSettings })
  }
}

export function showToast({ status, text }) {
  return function (dispatch) {
    dispatch({ type: T_STATUS_SHOW, payload: { status, text } })
  }
}

export function showLoading({ text }) {
  return function (dispatch) {
    dispatch({ type: T_STATUS_LOADING, payload: { text } })
  }
}

export function closeLoading() {
  return function (dispatch) {
    dispatch({ type: T_STATUS_CLOSE_LOADING, payload: '' })
  }
}

export function setNewKey(data) {
  return function (dispatch) {
    dispatch({ type: SET_NEW_MENU_KEY, payload: data })
  }
}

// My details

export function getMyDetails() {
  return function (dispatch) {
    return SecuredGetData(`/getmydetails`).then((resp) => {
      dispatch({ type: GET_MY_DETAILS, payload: resp.status_text })
    })
  }
}

// My Attendance

export function getMyAttendance() {
  return function (dispatch) {
    return SecuredGetData(`/getmyattendance`).then((resp) => {
      dispatch({ type: GET_MY_ATTENDANCE, payload: resp })
    })
  }
}

// My Leave Balance

export function getMyLeaveBalance() {
  return function (dispatch) {
    return SecuredGetData(`/getmyleavebalance`).then((resp) => {
      dispatch({ type: GET_MY_LEAVE_BALANCE, payload: resp })
    })
  }
}

// My Leave List

export function getMyLeave() {
  return function (dispatch) {
    return SecuredGetData(`/getmyleavelist`).then((resp) => {
      dispatch({ type: GET_MY_LEAVE, payload: resp.status_text })
    })
  }
}

// Holiday

export function getHoliday() {
  return function (dispatch) {
    return SecuredGetData(`/getholidayemployee`).then((resp) => {
      dispatch({ type: GET_HOLIDAY, payload: resp })
    })
  }
}

// Notice

export function getNotice() {
  return function (dispatch) {
    return SecuredGetData(`/getnotice`).then((resp) => {
      dispatch({ type: GET_NOTICE, payload: resp })
    })
  }
}

//Message

export function getMessage() {
  return function (dispatch) {
    return SecuredGetData(`/getmessage`).then((resp) => {
      let t = resp.map((el)=>el.message)
      dispatch({ type: GET_MESSAGE, payload: t })
    })
  }
}


//Payhead
export function getPayhead() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getpayhead`).then(
        (resp) => {
          dispatch({ type: GET_PAYHEAD, payload: resp })
        },
      )
    }
  }
}

export function initiateSocket() {
  return function (dispatch) {
      let socket = new SocketService();
      dispatch({ type: SOCKET_ON, payload: socket })
  }
}

export function toggleCollapseDashboard(value) {
  //console.log("🚀 ~ file: index.js ~ line 153 ~ toggleCollapseDashboard ~ value", value)
  return function (dispatch) {
    dispatch({ type: TOGGLE_COLLAPSE, payload: value })
  }
}


//Employee
export function getEmployee() {
  return function (dispatch) {
    if (authService.loggedIn()) {
      return SecuredGetData(`/getallemployees`).then(
        (resp) => {
          dispatch({ type: GET_EMPLOYEE, payload: resp.status_text })
        },
      )
    }
  }
}