import React from 'react'

import { ClockCircleOutlined, DashboardFilled, HomeOutlined, PaperClipOutlined, ProfileOutlined, SettingOutlined } from "@ant-design/icons";

export const APP_NAME = "HRM";
export const DEFAULT_COLOR = "#000000";
export const SECONDARY_DEFAULT_COLOR = "#E9A737";


export const DRAWER_WIDTH_LOW = 90;
export const DRAWER_WIDTH_HIGH = 240;
export const DRAWER_HEIGHT = 80;
export const DISPLAY_NAME = `Shishu Sarothi MIS`;
export const PRIMARY_COLOR = `#2A75BB`;

export const SERVER_NAME =   process.env.NODE_ENV === 'production' ? 'https://api.ss-mis.org/public' : 'http://localhost/shishu-sarothi-mis/api/public';
export const SERVER_NAME_FOR_UPLOADS =   process.env.NODE_ENV === 'production' ? 'https://api.ss-mis.org/public' : 'http://localhost/shishu-sarothi-mis/api/public';

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://api.ss-mis.org/public' : 'http://localhost/shishu-sarothi-mis/api/public';
export const UploadPath = process.env.NODE_ENV === 'production' ? 'https://api.ss-mis.org/src/uploads' : 'http://localhost/shishu-sarothi-mis/api/src/uploads';
export const WS_SERVER = process.env.NODE_ENV === 'production' ? 'wss://monitor.nehhdcmis.in' : 'http://localhost:4004';

export const navigationData = [
    {
        title: 'Home',
        path: '/mis',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'all',
        icon: <HomeOutlined />
    },

    {
        title: 'Profile',
        path: '/mis/employee/details/basic',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'all',
        icon: <ProfileOutlined />
    },
    // {
    //     title: 'Project Management',
    //     path: '/mis/projects',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'PROJECT_MANAGEMENT'

    // },
    {
        title: 'Projects',
        path: '/mis/projects',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'all',
        icon: <DashboardFilled />

    },
    {
        title: 'Templates & Links',
        path: '/mis/templates-links',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'all',
        icon: <PaperClipOutlined />

    },
    {
        title: 'Activities',
        path: '/mis/assigned-activity',
        breadcrumb: [],
        hasDropDown: true,
        subNav: [
            {
                title: 'To me',
                path: '/mis/assigned-activity',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'all',
                icon: <ClockCircleOutlined />
        
            },
            {
                title: 'By me',
                path: '/mis/assigned-activity-by-me',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'all',
                icon: <ClockCircleOutlined />
        
            },
        ],
        access:'all',
        icon: <ClockCircleOutlined />

    },
    {
        title: 'Shortcuts',
        path: '/mis/indent-recommendation',
        breadcrumb: [],
        hasDropDown: true,
        subNav: [
            {
                title: 'Indent Recommendation',
                path: '/mis/indent-recommendation',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'INDENT_RECOMMENDATION',
                icon: <PaperClipOutlined />
            },
            {
                title: 'Indent Approval',
                path: '/mis/indent-approval',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'INDENT_RECOMMENDATION_GRANT',
                icon: <PaperClipOutlined />
            },
            {
                title: 'Indent Approval (Secretary)',
                path: '/mis/indent-approval-secretary',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'INDENT_RECOMMENDATION_GRANT_SEC',
                icon: <PaperClipOutlined />
            },
            {
                title: 'Indent Accounts',
                path: '/mis/indent-approval-accounts',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'INDENT_RECOMMENDATION_ACCOUNTS',
                icon: <PaperClipOutlined />
            },
            {
                title: 'Invoices Approval',
                path: '/mis/invoice-approval',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'INVOICE_RECOMMENDATION_GRANT',
                icon: <PaperClipOutlined />
            },
            {
                title: 'Invoices Accounts',
                path: '/mis/invoice-recommendation',
                breadcrumb: [],
                hasDropDown: false,
                subNav: [],
                access:'INVOICE_RECOMMENDATION_ACCOUNTS',
                icon: <PaperClipOutlined />
            },
        ],
        access:'INDENT_RECOMMENDATION',
        icon: <PaperClipOutlined style={{fontSize: 18}} />
    },
   
    {
        title: 'Project Dashboard',
        path: '/mis/dashboard',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'REPORT_ACCESS',
        icon: <DashboardFilled />
    },
    {
        title: 'Activity Calendar',
        path: '/mis/activity-dashboard',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'REPORT_ACCESS',
        icon: <DashboardFilled />
    },
    {
        title: 'Indent Reports',
        path: '/mis/indent-reports',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'REPORT_ACCESS',
        icon: <DashboardFilled />
    },

    {
        title: 'Invoice Reports',
        path: '/mis/invoice-reports',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'REPORT_ACCESS',
        icon: <DashboardFilled />
    },
    {
        title: 'Change Password',
        path: '/mis/change-password',
        breadcrumb: [],
        hasDropDown: false,
        subNav: [],
        access:'all',
        icon: <SettingOutlined />

    },
    // {
    //     title: 'Leave',
    //     path: '/mis/leave',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'all',
    //     icon: <SettingOutlined />

    // },
    // {
    //     title: 'Pay Slip',
    //     path: '/hr/payslip',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'all'

    // },
    // {
    //     title: 'Movement Order',
    //     path: '/hr/movement',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'all'

    // },
    // {
    //     title: 'TA/DA Advance',
    //     path: '/hr/taadvance',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'all'

    // },
    // {
    //     title: 'Notice',
    //     path: '/mis/notice',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'all'

    // },
    // {
    //     title: 'Grievance',
    //     path: '/hr/grievance',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'all'

    // },
    // {
    //     title: 'Leave Recommendation (Department)',
    //     path: '/hr/othersleavedept',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'LEAVE_RECOMMENDATION_DEPARTMENT'

    // },
    // {
    //     title: 'Leave Grant (MD)',
    //     path: '/hr/othersleavemd',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'LEAVE_GRANT'

    // },
    // {
    //     title: 'Leave Recommendation',
    //     path: '/hr/othersleave',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'LEAVE_RECOMMENDATION'

    // },
    // {
    //     title: 'MO Recommendation',
    //     path: '/hr/mo-recommendation',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'MOVEMENT_ORDER_RECOMMENDATION'

    // },
    // {
    //     title: 'TA/DA (HR)',
    //     path: '/hr/tada-recommendation',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'TA_RECOMMENDATION'

    // },
    // {
    //     title: 'TA/DA (ACCOUNTS)',
    //     path: '/hr/tada-recommendation-ac',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'TA_GRANT_ACCOUNT'

    // },
    // {
    //     title: 'TA/DA (MD)',
    //     path: '/hr/tada-recommendation-md',
    //     breadcrumb: [],
    //     hasDropDown: false,
    //     subNav: [],
    //     access:'TA_GRANT_MD'

    // },
]

export default function number2text(value) {
    var fraction = Math.round(frac(value)*100);
    var f_text  = "";

    if(fraction > 0) {
        f_text = "and "+convert_number(fraction)+" paise ";
    }

    //return convert_number(value)+" rupee "+f_text+" only";
    return convert_number(value)+" rupees "+f_text+" Only.";
}

function frac(f) {
    return f % 1;
}

export function convert_number(number)
{
    if ((number < 0) || (number > 999999999)) 
    { 
        return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000);  /* Crore */ 
    number -= Gn * 10000000; 
    var kn = Math.floor(number / 100000);     /* lakhs */ 
    number -= kn * 100000; 
    var Hn = Math.floor(number / 1000);      /* thousand */ 
    number -= Hn * 1000; 
    var Dn = Math.floor(number / 100);       /* Tens (deca) */ 
    number = number % 100;               /* Ones */ 
    var tn= Math.floor(number / 10); 
    var one=Math.floor(number % 10); 
    var res = ""; 

    if (Gn>0) 
    { 
        res += (convert_number(Gn) + " crore"); 
    } 
    if (kn>0) 
    { 
            res += (((res=="") ? "" : " ") + 
            convert_number(kn) + " lakh"); 
    } 
    if (Hn>0) 
    { 
        res += (((res=="") ? "" : " ") +
            convert_number(Hn) + " thousand"); 
    } 

    if (Dn) 
    { 
        res += (((res=="") ? "" : " ") + 
            convert_number(Dn) + " hundred"); 
    } 


    var ones = Array("", "One", "Two", "Three", "Four", "Five", "Six","Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen","Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen","Nineteen"); 
var tens = Array("", "", "Twenty", "Thirty", "Fourty", "Fifty", "Sixty","Seventy", "Eighty", "Ninety"); 

    if (tn>0 || one>0) 
    { 
        if (!(res=="")) 
        { 
            res += " and "; 
        } 
        if (tn < 2) 
        { 
            res += ones[tn * 10 + one]; 
        } 
        else 
        { 

            res += tens[tn];
            if (one>0) 
            { 
                res += ("-" + ones[one]); 
            } 
        } 
    }

    if (res=="")
    { 
        res = "zero"; 
    } 
    return res;
}


export const STATUS_LIST = [
    {
        title: "Available",
        value: "AVAILABLE"
    },
    {
        title: "In a meeting",
        value: "IN_A_MEETING"
    },
    {
        title: "Commuting",
        value: "COMMUTING"
    },
    {
        title: "Off Sick",
        value: "OFF_SICK"
    },
    {
        title: "On Holiday",
        value: "ON_HOLIDAY"
    },
    {
        title: "Working Remotely",
        value: "WORKING_REMOTELY"
    },
]