import React, { Suspense, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Router, useNavigation, View } from 'react-navi'
import Layout from './Layout';
import Routes from './Routes/routes'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { authService } from './Helper/AuthService'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getMessage, initiateSocket, showNotification } from './redux/actions';
import store from "./redux/store/index";
import { useDispatch, useSelector } from 'react-redux';
import { T_STATUS_SUCCESS } from './redux/constants/action-types';
import NotificationSnack from './Components/Essentials/NotificationSnak';


const theme = createMuiTheme({
  palette: {
    secondary: {
      main: orange[500],
    },
    primary: {
      main: '#4385F3',
    },
  },
});

toast.configure({
  autoClose: 3000,
  draggable: false,
  pauseOnHover: true,
  position: 'bottom-right'
})


// store.dispatch(initiateSocket())


function App() {
  const [currentUser, setCurrentUser] = useState(() => authService.getCurrentUser())

  const dispatch = useDispatch();
  //const socket = useSelector(state => state.socket)

  // React.useEffect(() => {

  //   if (currentUser) {

  //     // now as we have connected so send student create message through socket
  //     socket.emit().emit(`addnewemployee`, {
  //       name: currentUser.name,
  //       emp_id: currentUser.emp_id
  //     })
  //   }


  // }, [currentUser])


  // React.useEffect(() => {
  //   if (socket) {
  //     const observable = socket.myType1('getnewdata1');
  //     observable.subscribe(({ message }) => {

  //       //console.log("🚀 ~ file: App.js ~ line 47 ~ observable.subscribe ~ data", message)

  //       if (message) {
  //         if (message.type == 'DEACTIVATE')
  //           authService.logout();
  //         if (message.type == 'MESSAGE')
  //           dispatch(getMessage())
  //         dispatch(showNotification({
  //           type: T_STATUS_SUCCESS,
  //           status: message.title,
  //           text: message.body,
  //           action: message.action,
  //           link: message.link
  //         }))
  //         const audio = new Audio('/resources/pristine.mp3');
  //         audio.play();
  //       }
  //     });
  //   }


  // }, [socket])
  return (
    <ThemeProvider theme={theme}>
      <Router routes={Routes} context={{ authService, currentUser }}>
        <NotificationSnack />
        <Layout>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
