import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  TextField,
  CardActionArea,
  Button,
  Typography,
} from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import { green, red } from "@material-ui/core/colors";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#3D2D70",
  },
  resize: {
    fontSize: 18,
  },
  resizeLable: {
    fontSize: 15,
    fontWeight: 400,
  },
  resize: {
    fontSize: 18,
  },
  resizeLable: {
    fontSize: 15,
    fontWeight: 400,
  },
  forgotCard: {
    width: 400,
    padding: "20px",
  },
  colorMessage: {
    color: red[300],
  },
});

class FormThree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_password: "",
      confirm_password: "",
      error: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.new_password === this.state.confirm_password) {
      this.props.setPassword(this.state.new_password);
    } else {
      this.setState({
        error: "Passwords donot match",
      });
    }
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container}>
        <form onSubmit={this.onSubmit}>
          <Card className={classes.forgotCard}>
            <div align="center">
              <img
                src={`/logo512.png`}
                style={{ width: 80, marginBottom: 10 }}
              />

              <Typography variant="h4" style={{ color: "black" }} gutterBottom>
                Shishu Sarothi MIS
              </Typography>
            </div>
            <CardHeader title={<div align="center">Forgot Password</div>} />
            <CardContent>
              <div align="center">
                <Input.Password
                  autoFocus
                  type="password"
                  name="new_password"
                  value={this.state.new_password}
                  onChange={this.handleChange}
                  required={true}
                  placeholder="Enter new password here"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
                <br />
                <br />
                <Input.Password
                  type="password"
                  name="confirm_password"
                  value={this.state.confirm_password}
                  onChange={this.handleChange}
                  required={true}
                  placeholder="Confirm Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </div>

              {/* <Typography
                                variant="subtitle2"
                                className={classes.colorMessage}
                            >
                            Enter new password here
                            </Typography>

                            <TextField
                                required
                                label="New Password"
                                type="password"
                                name="new_password"
                                value={this.state.new_password}
                                onChange={this.handleChange}
                                margin="normal"
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.resizeLable
                                    }
                                }}
                            /> */}

              {/* <TextField
                                required
                                label="Confirm Password"
                                type="password"
                                name="confirm_password"
                                value={this.state.confirm_password}
                                onChange={this.handleChange}
                                margin="normal"
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.resizeLable
                                    }
                                }}
                            /> */}

              <Typography variant="subtitle2" style={{ color: "red" }}>
                {this.state.error}
              </Typography>
            </CardContent>

            <CardContent style={{ textAlign: "right" }}>
              <div>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </div>
            </CardContent>


            <div style={{ textAlign: "center" }}>
              <a style={{ color: "blue" }} href="/login">
                <u>Go to login</u>
              </a>
            </div>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(FormThree);
