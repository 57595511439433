import React, { Component } from "react";
import FormOne from "./FormOne";
import { withStyles } from "@material-ui/core";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import { PostData } from "../../Service/service";
import { message } from "antd";

const styles = (theme) => ({});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: 1,
      email: "",
      message: "",
      otp: "",
      error_one: "",
    };
  }

  changeForm = (id) => {
    this.setState({
      form: id,
    });
  };

  setEmail(email) {
    const data = {
      email: email,
    };

    PostData("/forgotpassword", data).then((resp) => {
      if (resp !== false) {
        this.setState({
          email: email,
          message: resp,
          form: 2,
        });
      } else {
        this.setState({
          error_one: "No entry found with this email id",
        });
      }
    });
  }

  setOtp(otp) {
    const data = {
      otp: otp,
      email: this.state.email,
    };
    PostData("/forgotpassword/otp", data).then((resp) => {
      if (resp === true) {
        this.setState({
          form: 3,
          message: "",
          otp: otp,
        });
      } else {
        this.setState({
          message: "Wrong OTP! Please try again.",
        });
      }
    });
  }

  setPassword(password) {
    const data = {
      email: this.state.email,
      password: password,
      otp: this.state.otp,
    };

    PostData("/forgotpassword/final", data).then((resp) => {
      //console.log(resp)
      if (resp === true) {
        message.success(`Password successfully changed`);
        window.location.href = "/login";
        // confirmAlert({
        //     title: 'Successfully Updated',
        //     message: 'You will be redirected to the login page',
        //     buttons: [
        //         {
        //             label: 'OKAY',
        //             onClick: () => this.props.history.replace('/login')
        //         }
        //     ]
        // })
      } else {
        message.error(`Something went wrong, kindly try after some time.`);
        // confirmAlert({
        //     title: 'Something Went Wrong',
        //     message: 'Please try again later',
        //     buttons: [
        //         {
        //             label: 'OKAY',
        //             onClick: () => this.props.history.replace('/login')
        //         }
        //     ]
        // })
      }
    });
  }

  render() {
    switch (this.state.form) {
      case 1:
        return (
          <FormOne
            setEmail={this.setEmail.bind(this)}
            error={this.state.error_one}
          />
        );

      case 2:
        return (
          <FormTwo
            message={this.state.message}
            email={this.state.email}
            setOtp={this.setOtp.bind(this)}
          />
        );

      case 3:
        return <FormThree setPassword={this.setPassword.bind(this)} />;

      default:
        break;
    }
  }
}

export default withStyles(styles)(ForgotPassword);
