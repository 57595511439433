import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  TextField,
  CardActionArea,
  Button,
  Typography,
} from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#3D2D70",
  },
  resize: {
    fontSize: 18,
  },
  resizeLable: {
    fontSize: 15,
    fontWeight: 400,
  },
  resize: {
    fontSize: 18,
  },
  resizeLable: {
    fontSize: 15,
    fontWeight: 400,
  },
  forgotCard: {
    width: 400,
    padding: "20px",
  },
});

class FormOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.setEmail(this.state.email);
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container}>
        <form onSubmit={this.onSubmit}>
          <Card className={classes.forgotCard}>
            <div align="center">
              <img
                src={`/logo512.png`}
                style={{ width: 80, marginBottom: 10 }}
              />

              <Typography variant="h4" style={{ color: "black" }} gutterBottom>
                Shishu Sarothi MIS
              </Typography>
            </div>
            <CardHeader title={<div align="center">Forgot Password</div>} />
            <CardContent>
              <div align="center">
                <Input
                  autoFocus={true}
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  size="large"
                  placeholder="Please enter your email id"
                  prefix={<UserOutlined />}
                  required={true}
                />

                {/* <TextField
                                required
                                label="Please enter your email id"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                margin="normal"
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.resizeLable
                                    }
                                }}
                            /> */}

                <Typography variant="subtitle2" style={{ color: "red" }}>
                  {this.props.error}
                </Typography>
              </div>
            </CardContent>

            <CardContent style={{ textAlign: "right" }}>
              <Button variant="contained" type="submit">
                Next <Send />
              </Button>
            </CardContent>

            <div style={{ textAlign: "center" }}>
              <a style={{ color: "blue" }} href="/login">
                <u>Go to login</u>
              </a>
            </div>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(FormOne);
